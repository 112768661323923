import React from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby'
import { colors, breakpoints, fonts } from '../styles/variables'

const Container = styled.button`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 400px;
  border: none;
  background: none;
  cursor: pointer;

  @media ${`(max-width: ${breakpoints.md}px)`} {
    width: 100%;
    max-height: 300px;
    margin-bottom: 15px;
  }
`

const Image = styled.img`
  width: 100%;
  max-width: 550px;
  max-height: 400px;
  justify-content: center;
  margin-left: 0;
  object-fit: cover;
  overflow: hidden;

  @media ${`(max-width: ${breakpoints.xl}px)`} {
    width: 100%;
    max-width: 400px;
    max-height: 300px;
    margin-left: 0;
  }

  @media ${`(max-width: ${breakpoints.md}px)`} {
    margin-left: 50%;
    transform: translate(-50%);
    width: 100%;
    max-width: 387px;
    max-height: 230px;
  }
`

const TextContainer = styled.div`
  position: absolute;
  bottom: 0;
  height: 100%;
  max-width: 550px;
  left: 50%;
  transform: translate(-50%);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background: linear-gradient(360deg, #2b2b2b 11.5%, rgba(94, 94, 94, 0) 62.12%);

  @media ${`(max-width: ${breakpoints.xl}px)`} {
    width: 100%;
    max-width: 400px;
    max-height: 300px;
  }

  @media ${`(max-width: ${breakpoints.md}px)`} {
    width: 96.5%;
    max-width: 387px;
    max-height: 230px;
    margin-right: 100px;
  }
`

const Text = styled.div`
  display: flex;
  flex-direction: row;
  height: 60px;
  justify-content: center;
  align-content: center;
  align-items: center;
  font-family: ${fonts.montserrat};
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  text-align: right;
  color: ${colors.white};
  text-decoration: none;

  @media ${`(max-width: ${breakpoints.md}px)`} {
    font-size: 14px;
    line-height: 17px;
    height: 80px;
  }
`

interface Props {
  title: string
  imageSrc: string
  type: string
}

const CategoryImageElement: React.FC<Props> = ({ title, imageSrc, type }) => {
  let categoryUrl = ''

  switch (type) {
    case 'latest':
      categoryUrl = 'uusimmat'
      break
    case 'favorites':
      categoryUrl = 'suosikit'
      break
    case 'recommended':
      categoryUrl = 'suosituimmat'
      break
    case 'progress':
      categoryUrl = 'jatkakuuntelua'
      break
    case 'podcast':
      categoryUrl = 'podcastit'
      break
    default: {
      categoryUrl = encodeURIComponent(title)
      break
    }
  }

  const navigateTo = () => {
    navigate(`/listasivu/${categoryUrl}`)
  }

  return (
    <Container onClick={navigateTo}>
      <Image src={imageSrc ? imageSrc : '/images/dei_plus_category_placeholder.png'} />
      <TextContainer>
        <Text>{title}</Text>
      </TextContainer>
    </Container>
  )
}
interface VideoProps {
  title: string
  imageSrc: string
  type: string
}

const VideoCategoryImageElement: React.FC<VideoProps> = ({ title, imageSrc, type }) => {
  let categoryUrl = ''

  switch (type) {
    case 'latest':
      categoryUrl = 'uusimmat'
      break
    case 'favorites':
      categoryUrl = 'suosikit'
      break
    case 'recommended':
      categoryUrl = 'suosituimmat'
      break
    case 'progress':
      categoryUrl = 'jatkakuuntelua'
      break
    case 'podcast':
      categoryUrl = 'podcastit'
      break
    default: {
      categoryUrl = encodeURIComponent(`${title}+${'-videos'}`)
      break
    }
  }

  const navigateTo = () => {
    navigate(`/listasivu/${categoryUrl}`)
  }

  return (
    <Container onClick={navigateTo}>
      <Image src={imageSrc ? imageSrc : '/images/dei_plus_category_placeholder.png'} />
      <TextContainer>
        <Text>{title}</Text>
      </TextContainer>
    </Container>
  )
}
export { CategoryImageElement, VideoCategoryImageElement }
